<div *transloco="let t; read: 'unauthorized'" class="m-0 d-flex align-items-center flex-column">
  <div class="image mt-5">
    <img class="h-100 w-100" src="assets/images/user-accessed-unauthorized-page.png" alt="Ouch!" srcset="" />
  </div>
  <div class="message mt-5 px-5">
    <span class="message__sentence-1 text-danger font-weight-bold">
      {{ t("ouchText") }}
    </span>
    <span class="message__sentence-2">
      {{ t("noAccessMessage") }}
    </span>
  </div>
  <div class="button-container my-5">
    <button type="button" class="btn btn-synlab w-100" (click)="logout()">{{ t("logoutButton") }}</button>
  </div>
</div>
