<div class="component-wrapper pt-3" *transloco="let t1; read: tkey1">
  <div class="d-flex">
    <div>
      <h3>
        {{ t1("smsTitle") }} <span class="phone">({{ phoneNumber$ | async }})</span>
      </h3>
      <p>{{ t1("sms2FAExplanationText") }}</p>
    </div>
    <div class="pl-5 tw-whitespace-nowrap actions">
      <div *ngIf="(editPhoneToggle$ | async) != true && (smsCodeEnableMFAToggle$ | async) != true; else showCancel">
        <button class="btn btn btn-link mr-4 p-0" (click)="toggleEditSmsOnclick(true)">
          {{ t1("editLabel") | uppercase }}
        </button>
        <button class="btn btn btn-link p-0 btn-enable" (click)="enableMFA('phone')" [disabled]="enableSMSLoading">
          <span class="m-0 p-0 d-flex justify-content-center align-items-center">
            <span *ngIf="enableSMSLoading; else showEnableText">
              <mat-spinner diameter="24" strokeWidth="4"></mat-spinner>
            </span>
            <ng-template #showEnableText>{{ t1("enableButton") | uppercase }} </ng-template>
          </span>
        </button>
      </div>
      <ng-template #showCancel>
        <div class="btn-cancel tw-flex tw-flex-row-reverse">
          <button class="btn btn btn-link p-0" (click)="resetView()">{{ t1("cancelButton") | uppercase }}</button>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="sms-code-enable-mfa">
    <!-- Enter sms code to enable sms 2fa -->
    <div
      class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5"
      *ngIf="(smsCodeEnableMFAToggle$ | async) == true; else showUpdatePhoneContainer"
    >
      <h5>{{ t1("codeLabel1") | uppercase }}</h5>
      <div class="d-flex code-submit">
        <input type="text" class="form-control" [formControl]="smsCodeEnableMfaFC" id="smsCodeEnableMfaFC" />
        <div class="submit ml-4">
          <button
            [disabled]="smsCodeEnableMfaFC.invalid || (getSMSMFACodeLoading$ | async) == true"
            (click)="enableMFAVerify('Phone')"
            class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
          >
            <span class="loading" *ngIf="(getSMSMFACodeLoading$ | async) == true; else showText1">
              <mat-spinner diameter="24" strokeWidth="4"></mat-spinner>
            </span>
            <ng-template #showText1>
              <img src="assets/images/registerv2/arrow-right.png" alt="Icon" />
              <span class="pl-1">{{ t1("verifyButton") | uppercase }}</span>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #showUpdatePhoneContainer>
    <div class="update-phone-container">
      <!-- Enter new mobile number -->
      <div
        class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5"
        *ngIf="(editPhoneToggle$ | async) == true && (smsCodePhoneUpdateToggle$ | async) == false"
      >
        <h5>{{ t1("mobileNumberLabel") }}</h5>
        <div class="d-flex" [formGroup]="form">
          <app-mobile-number-picker
            [name]="'phone'"
            (fgEvent)="validatePhoneFormGroupValues($event)"
            [countryControl]="form.get('country_phone')"
            [numberControl]="form.get('phone')"
            [selectedCountryISO]="form.get('country_phone')?.getRawValue()"
          ></app-mobile-number-picker>

          <div class="submit ml-4">
            <button
              class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
              [disabled]="!isValid || (editPhoneLoading$ | async) == true"
              (click)="updatePhoneNumber()"
            >
              <span class="loading" *ngIf="(editPhoneLoading$ | async) == true; else showText2">
                <mat-spinner diameter="24" strokeWidth="4"></mat-spinner>
              </span>
              <ng-template #showText2>
                <img src="assets/images/registerv2/arrow-right.png" alt="Icon" />
                <span class="pl-1">{{ t1("sms2FAInstructionText.text2") | uppercase }}</span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>

      <!-- Enter sms code to confirm new mobile number -->
      <div class="phone-number tw-rounded-md tw-p-5 tw-mt-4 mb-5" *ngIf="(smsCodePhoneUpdateToggle$ | async) == true">
        <h5>{{ t1("codeLabel1") | uppercase }}</h5>
        <div class="d-flex code-submit">
          <input type="text" class="form-control" [formControl]="smsCodeFC" id="smsCodeFC" />
          <div class="submit ml-4">
            <button
              [disabled]="smsCodeFC.invalid || (editPhoneLoading$ | async) == true"
              (click)="verifyPhone()"
              class="btn btn-primary d-flex tw-justify-center tw-items-center btn-send-code"
            >
              <span class="loading" *ngIf="(editPhoneLoading$ | async) == true; else showText3">
                <mat-spinner diameter="24" strokeWidth="4"></mat-spinner>
              </span>
              <ng-template #showText3>
                <img src="assets/images/registerv2/arrow-right.png" alt="Icon" />
                <span class="pl-1">{{ t1("verifyButton") }}</span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
