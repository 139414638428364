<dxp-background></dxp-background>

<div class="forgot-password-wrapper" *transloco="let t; read: 'sendForgotPassword'">
  <div class="forgot-password-card">
    <h1 class="forgot-password-title">{{ t("shfyHeading") }}</h1>
    <p class="forgot-password-message mb-0">{{ t("shfyMessage") }}</p>

    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="forgot-password-input-group">
        <input
          formControlName="email"
          type="email"
          class="forgot-password-input"
          placeholder="Email"
          [ngClass]="{ 'is-invalid': form.invalid && form.touched }"
        />
        <span *ngIf="email.value" class="input-clear-icon" (click)="onClear()">
          <em class="fa fa-times"></em>
        </span>
      </div>

      <div class="action-wrapper">
        <button (click)="onCancel()" type="button" class="cancel-btn sm-margin">
          {{ t("shfyCancel") }}
        </button>
        <button class="forgot-password-btn sm-margin">
          <i class="fa fa-arrow-right forgot-password-btn-icon" aria-hidden="true"></i>
          {{ t("shfyProceed") }}
        </button>
      </div>
    </form>
  </div>

  <div
    *ngIf="showMessage && submitted"
    class="alert alert-success"
    [innerHTML]="formatSuccessMessage(t('shfySendSuccess'))"
  ></div>
</div>
