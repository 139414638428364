import { HttpClient } from "@angular/common/http";
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
} from "@ngneat/transloco";
import { Injectable, NgModule, APP_INITIALIZER } from "@angular/core";
import { environment } from "../environments/environment";
import { I18nService } from "./services/i18n/i18n.service";
import { ConfigService } from "./services/config/config.service";
import { NgbDateParserFormatter, NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { CustomNgbDateParserFormatterService } from "./services/i18n/custom-ngb-date-parser-formatter.service";
import { CustomNgbDatepickeri18nService } from "./services/i18n/custom-ngb-datepickeri18n.service";
import { catchError, filter, shareReplay, take, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { AppActions } from "src/app/@store/actions/app.actions";
import { of } from "rxjs";
import memoizee from "memoizee";
import { LocalStorageKeys } from "./models/storage.model";
import { getLangCodeFromLocalStorage, getLangCodeFromQueryString } from "./utils/common.utils";
import { formatLocale } from "./plasma-ui-common/utils/format-locale";

/** List of language codes which uses new format of terms and policy */
export const newFormatAgreementLanguages = ["de", "en"];

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private store: Store<AppState>
  ) {}

  getTranslation = memoizee((lang: string) => {
    this.store.dispatch(AppActions.loadAppLanguage({ language: lang }));
    return this.http
      .get<Translation>(`/assets/i18n/${formatLocale(lang)}.json`, {
        params: { version: this.config._envConfig.Version },
      })
      .pipe(
        tap(() => this.store.dispatch(AppActions.loadAppLanguageSuccess({ language: formatLocale(lang) }))),
        catchError((err) => {
          this.store.dispatch(
            AppActions.loadAppLanguageFailure({
              language: formatLocale(lang),
              error: err,
            })
          );
          return of(null);
        }),
        shareReplay({ refCount: false, bufferSize: 1 })
      );
  });
}

@NgModule({
  //imports: [TranslocoLocale],
  exports: [TranslocoModule],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomNgbDateParserFormatterService,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: CustomNgbDatepickeri18nService,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          "en" /** english */,
          "hu" /** hungary */,
          "es" /** Colombian Spanish */,
          "pt" /** portugal */,
          "fr" /** france */,
          "it" /** italy */,
          "de" /** german */,
          "el" /** Greek */,
          "lt" /** Lithuanian */,
          "tr" /** Turkish */,
          "et",
        ],
        defaultLang: "en",
        missingHandler: {
          logMissingKey: true,
          useFallbackTranslation: true,
        },
        fallbackLang: "en",
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    I18nService,
    /** post-transloco-import setup because config data cannot be fetched
     * before transloco config is setup as provider
     * * Loads available language from config
     * * sets current language after available languages are set
     */
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, TranslocoService, I18nService],
      useFactory: (configService: ConfigService, transloco: TranslocoService, i18n: I18nService) => {
        return () => {
          return configService.envConfig$
            .pipe(
              filter((x) => Boolean(x)),
              take(1),
              tap((config) => {
                const configLanguages = config.AvailableLanguage && config.AvailableLanguage.split(",");
                const allAvailableLanguages = [...(Array.isArray(configLanguages) ? configLanguages : [])].map((lang) =>
                  formatLocale(lang)
                );
                const availableLanguages = [...new Set(allAvailableLanguages)];

                transloco.setDefaultLang(config.DefaultLanguage);
                transloco.setAvailableLangs(availableLanguages);
                i18n.detectBrowserLocale();

                const getLocale: Function = isInAvailableLocale;
                const locale: string = getLangCodeFromQueryString();
                const savedLocale: string = getLangCodeFromLocalStorage();

                const t = formatLocale(getLocale(locale, availableLanguages), <any>config);
                if (locale && t != "") {
                  transloco.setActiveLang(t);
                  sessionStorage?.setItem(LocalStorageKeys.language, t);
                } else if (savedLocale) {
                  // set activeLanguage from localStorage
                  transloco.setActiveLang(savedLocale?.toLowerCase());
                } else {
                  // set activeLanguage from <config.DefaultLanguage>
                  const initLang: string = formatLocale(getLocale(locale, availableLanguages), <any>config);
                  transloco.setActiveLang(initLang);
                  sessionStorage?.setItem(LocalStorageKeys.language, initLang);
                }
              })
            )
            .toPromise();
        };
      },
    },
  ],
})
export class TranslocoRootModule {}

export function isInAvailableLocale(locale: string, available: string[]): string {
  if (!locale) {
    return "";
  }
  if (available.map((l) => l.toLowerCase()).indexOf(locale?.toLowerCase()) > -1) {
    return locale.length == 2 ? locale.toLowerCase() : locale;
  }
  if (locale.split("-").length > 1) {
    return isInAvailableLocale(locale.split("-")[0], available);
  }
  return "";
}
