<ng-container *transloco="let t; read: 'forgotPassword'">
  <div *ngIf="isSHFYInstance$ | async" class="background">
    <img src="/assets/dxp/img/background.png" alt="" srcset="" />
  </div>

  <ng-container *ngIf="!(useNewNav$ | async)">
    <app-header [showDropdown]="false"></app-header>
  </ng-container>
  <div class="container" *ngIf="showSpin">
    <p class="text-center pt-5 pb-2">
      <img src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner d-inline" />
    </p>
  </div>

  <div class="container" *ngIf="!showSpin && !showForm">
    <ng-container *ngIf="useNewNav$ | async; else oldWarning">
      <div class="syn-container">
        <h2>{{ t("invalidLinkText") }}</h2>
        <button (click)="redirectToContactSynlab()">
          <mat-icon>arrow_forward</mat-icon>
          {{ "completeProfile.openUefaLink" | transloco }}
        </button>
      </div>
    </ng-container>
    <ng-template #oldWarning>
      <div class="alert alert-warning mt-5" role="alert">
        {{ t("invalidLinkText") }}
      </div>
    </ng-template>
  </div>

  <div [ngClass]="{ 'is-dxp': (isSHFYInstance$ | async) }" class="container pt-5 pb-5" *ngIf="!showSpin && showForm">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card profile-secure-wrappper pl-box-shadow-1">
          <div class="card-body pb-5 p-0">
            <h3 class="title-mb f-6 py-3 px-4">{{ t("resetYourPasswordTitle") }}</h3>
            <p class="text-center pt-5 pb-2 mb-5" *ngIf="loading">
              <img src="assets/images/spinner.gif" alt="spinner" class="img-fluid spinner d-inline" />
            </p>
            <div class="wrapper py-3 px-4" *ngIf="!loading">
              <div class="alert alert-danger" role="alert" hidden>
                <p class="text-center">
                  <strong>{{ t("noteText") }}:</strong> <br />
                  {{ t("completeRegistrationText.text1") }}
                  <br />
                  {{ t("completeRegistrationText.text2") }}
                </p>
              </div>

              <!-- Account Password -->
              <div class="row">
                <div class="col-12 col-sm-12">
                  <form autocomplete="off" [formGroup]="passwordFormG" (ngSubmit)="setAccountPassword()">
                    <div class="row justify-content-center" *ngIf="passwordFormG as fp">
                      <div class="col-12 col-sm-9 pt-4">
                        <ng-container *ngIf="isSHFYInstance$ | async">
                          <ng-container *ngTemplateOutlet="passwordHelpBlock"></ng-container>
                        </ng-container>

                        <!-- new password -->
                        <div class="form-group">
                          <label *ngIf="!(isSHFYInstance$ | async)" class="f-2" for="">{{
                            t("enterPasswordLabel")
                          }}</label>
                          <input
                            type="password"
                            class="form-control"
                            [formControlName]="EPassForm.NEW_PASS"
                            [id]="EPassForm.NEW_PASS"
                            [ngClass]="{
                              'border-danger': fp.get(EPassForm.NEW_PASS).errors && fp.get(EPassForm.NEW_PASS).dirty,
                            }"
                            [placeholder]="(isSHFYInstance$ | async) ? t('enterPasswordLabel') : ''"
                          />
                        </div>

                        <!-- confirm password -->
                        <div class="form-group">
                          <label *ngIf="!(isSHFYInstance$ | async)" class="f-2" for="">{{
                            t("confirmPasswordLabel")
                          }}</label>
                          <input
                            type="password"
                            class="form-control"
                            [formControlName]="EPassForm.CONFIRM_PASS"
                            [id]="EPassForm.CONFIRM_PASS"
                            [ngClass]="{
                              'border-danger': fp.errors?.confirmPassword && fp.get(EPassForm.CONFIRM_PASS).dirty,
                            }"
                            [placeholder]="(isSHFYInstance$ | async) ? t('confirmPasswordLabel') : ''"
                          />
                          <small *ngIf="fp.errors?.confirmPassword" class="text-danger">
                            {{ t("passwordDontMatchError") }}
                          </small>

                          <ng-container *ngIf="!(isSHFYInstance$ | async)">
                            <ng-container *ngTemplateOutlet="passwordHelpBlock"></ng-container>
                          </ng-container>
                        </div>

                        <!-- submit button -->
                        <div class="buttons mt-4">
                          <button
                            [disabled]="fp.invalid"
                            id="save_pass_button"
                            class="btn btn-primary px-4 float-right"
                            type="submit"
                          >
                            <i
                              *ngIf="isSHFYInstance$ | async"
                              class="fa fa-arrow-right forgot-password-btn-icon"
                              aria-hidden="true"
                            ></i>
                            {{ t("savePasswordButton") }}
                          </button>
                          <span class="clearfix"></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #passwordHelpBlock>
    <small id="passwordHelpBlock" class="form-text pt-2 text-muted">
      {{ t("passwordInstructionText") }}
    </small>
  </ng-template>
</ng-container>
