<ng-container *ngIf="isUkShfyInstance$ | async; else originalForgotPasswordPage">
  <app-dxp-send-forgot-password></app-dxp-send-forgot-password>
</ng-container>

<ng-template #originalForgotPasswordPage>
  <ng-container *transloco="let t; read: 'sendForgotPassword'">
    <div class="main-container">
      <div class="cardx shadow-sm">
        <img
          class="logo"
          [src]="(isDKInstance$ | async) ? '/assets/images/new/branding-logo.png' : '/assets/img/synlab-logo.svg'"
          [alt]="(isDKInstance$ | async) ? 'Covidresults.dk logo' : 'SYNLAB logo'"
        />
        <p>
          {{
            (isDKInstance$ | async)
              ? (t("heading") | textReplacement: "SYNLAB Access" : "Covidresults.dk")
              : t("heading")
          }}
        </p>
        <form [formGroup]="form" (submit)="onSubmit()" id="emailEntryForm">
          <div class="email-input">
            <input
              formControlName="email"
              type="text"
              class="form-control-lg form-control"
              [placeholder]="t('email')"
              [ngClass]="{ 'is-invalid': form.invalid && form.touched }"
            />
            <span id="clearInputTrigger" *ngIf="email.value" role="button" (click)="onClear('email')">
              <em class="fa fa-times"></em>
            </span>
          </div>

          <div class="action-buttons pt-2">
            <button id="cancelButton" class="btn btn-light" type="button" (click)="onCancel()">
              {{ t("cancel") }}
            </button>
            <button class="btn btn-primary" type="submit" [disabled]="form.invalid || loading || submitted">
              {{ t("proceed") }}
            </button>
          </div>
        </form>
      </div>
      <div
        *ngIf="message && submitted"
        class="alert alert-success"
        [innerHTML]="t(message, { email: email.value })"
      ></div>
      <div *ngIf="isUserDeactivated && submitted" class="alert alert-danger" [innerHTML]="deactivatedMessage"></div>
    </div>
    <div class="bg-image"></div>
  </ng-container>
</ng-template>
