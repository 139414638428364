import { InjectionToken } from "@angular/core";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { StoreService } from "src/app/services/store.service";

import * as STSReducer from "./sts.reducer";
import * as ProfileReducer from "./profile.reducer";
import * as AppReducer from "./app.reducer";
import * as RegisterProfileReducer from "./register-profile.reducer";
import * as RegistrationReducer from "./registration.reducer";
import * as TaraRegisterProfileReducer from "./tara-register-profile.reducer";
import * as HeaderReducer from "./header.reducer";
import * as DKMFAResetReducer from "../dk-mfa-reset/dk-mfa-reset.reducer";
import { legalDocumentFeatureKey, legalDocumentsReducer } from "../legal-documents/legal-documents.store";
import { LegalDocumentStateInterface } from "src/app/interfaces/legal-document-state.interface";

export interface AppState {
  [AppReducer.appFeatureKey]: AppReducer.State;
  [STSReducer.stsFeatureKey]: STSReducer.State;
  [ProfileReducer.profileFeatureKey]: ProfileReducer.State;
  [RegisterProfileReducer.featureKey]: RegisterProfileReducer.State;
  [RegistrationReducer.registrationFeatureKey]: RegistrationReducer.State;
  [TaraRegisterProfileReducer.featureKey]: TaraRegisterProfileReducer.State;
  [HeaderReducer.headerFeatureKey]: HeaderReducer.State;
  [DKMFAResetReducer.dkMfaResetFeatureKey]: DKMFAResetReducer.State;
  [legalDocumentFeatureKey]: LegalDocumentStateInterface;
}

export const reducers: ActionReducerMap<AppState> = {
  app: AppReducer.reducer,
  sts: STSReducer.reducer,
  profile: ProfileReducer.reducer,
  registerProfile: RegisterProfileReducer.reducer,
  registration: RegistrationReducer.reducer,
  taraRegisterProfile: TaraRegisterProfileReducer.reducer,
  [HeaderReducer.headerFeatureKey]: HeaderReducer.reducer,
  dkMfaReset: DKMFAResetReducer.reducer,
  [legalDocumentFeatureKey]: legalDocumentsReducer,
};

/** log all actions */
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [debug];
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>("Registered Reducers");

export function getReducers(s: StoreService) {
  return s.getReducers();
}
